import UpdateCustomer from '@/js/update-customer';

class LogCustomerVisit extends UpdateCustomer {
  constructor() {
    super();
    this.params = {
      shopify_domain: window.Shopify.shop,
      timestamp: this.timestamp
    };

    const localTimestamp = localStorage.getItem('lastVisit');

    if (this.customerId && this.timestamp !== localTimestamp) {
      document.addEventListener('DOMContentLoaded', () => {
        this.updateTimestamp(this.params);
      });
    }
  }

  successCallback() {
    localStorage.setItem('lastVisit', this.timestamp);
  }
}

customElements.get('log-customer-visit') ||
  customElements.define('log-customer-visit', LogCustomerVisit);
